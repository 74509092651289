<template>
  <nav
    v-if="groups?.length"
    :class="ui.wrapper"
    v-bind="attrs"
  >
    <template
      v-for="(group, index) in groups"
      :key="index"
    >
      <UNavigationAccordion
        v-if="group.type === 'accordion'"
        :links="group.children"
        :level="level"
        :multiple="multiple"
        :default-open="defaultOpen"
        :ui="accordionUi"
      />

      <UNavigationLinks
        v-else
        :links="group.children"
        :level="level"
      />
    </template>
  </nav>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { NavigationGroup, NavigationTree } from '#ui-pro/types'

const config = {
  wrapper: 'space-y-3',
}

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  level: {
    type: Number,
    default: 0,
  },
  links: {
    type: Array as PropType<NavigationTree[]>,
    default: () => [],
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  defaultOpen: {
    type: [Boolean, Number],
    default: undefined,
  },
  class: {
    type: [String, Object, Array] as PropType<any>,
    default: undefined,
  },
  ui: {
    type: Object as PropType<Partial<typeof config>>,
    default: () => ({}),
  },
})

const { ui, attrs } = useUI(
  'navigation.tree',
  toRef(props, 'ui'),
  config,
  toRef(props, 'class'),
  true
)

const accordionUi = {
  wrapper: 'space-y-3',
  container: 'space-y-3',
  item: {
    padding: '',
    color: 'text-text-2 dark:text-text-2',
  },
  button: {
    base: 'flex items-center gap-1.5 group w-full focus-visible:outline-primary',
    active: 'text-primary border-none',
    inactive: 'border-transparent',
    level: 'border-l -ml-px pl-3.5',
    icon: {
      base: 'w-5 h-5 flex-shrink-0',
    },
    trailingIcon: {
      name: 'i-heroicons-chevron-down-20-solid',
      base: 'w-5 h-5 ms-auto transform transition-transform duration-200 flex-shrink-0 mr-1.5',
      active: 'text-text-1 dark:text-text-1',
      inactive:
        'text-text-2 dark:text-text-2 group-hover:text-text-1 dark:group-hover:text-text-1 -rotate-90',
    },
    label: 'text-md font-semibold truncate',
  },
  tree: 'border-l border-text-3 dark:border-text-3',
}

const groups = computed<NavigationGroup[]>(() => {
  const groups: NavigationGroup[] = []

  let group: NavigationGroup = { type: undefined, children: [] }

  for (const link of props.links) {
    const type = link.children?.length ? 'accordion' : 'link'
    if (!group.type) {
      group.type = type
    }

    if (group.type === type) {
      group.children.push(link)
    } else {
      groups.push(group)
      group = { type, children: [link] }
    }
  }

  if (group.children.length) {
    groups.push(group)
  }

  return groups
})
</script>
