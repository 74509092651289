<template>
  <UHeader
    ref="headerRef"
    class="border-b border-block-4 bg-bg/60 backdrop-blur dark:bg-bg/80"
    :to="localePath('/')"
  >
    <template #logo>
      <template v-if="header?.logo?.dark || header?.logo?.light">
        <Logo />
      </template>
    </template>
    <template #panel>
      <MainNavigationTree
        :default-open="false"
        :links="links"
      />
    </template>

    <template #panel-button>
      <UButton
        class="lg:hidden"
        variant="ghost"
        color="gray"
        :aria-label="`${isHeaderDialogOpen ? 'Close' : 'Open'} Menu`"
        :icon="
          isHeaderDialogOpen
            ? 'i-heroicons-x-mark-20-solid'
            : 'i-heroicons-bars-3-20-solid'
        "
        @click="onMenuButtonClick"
      />
    </template>

    <template #center>
      <ul class="hidden items-center gap-6 lg:flex">
        <li
          v-for="(link, key) in links"
          :id="link.id"
          :key="key"
        >
          <div
            v-if="link.children"
            :id="link.id"
            :data-parent-id="link.id"
            class="hover:text-primary flex cursor-pointer items-center gap-1 text-sm/6 font-semibold"
            :class="{ 'text-primary': openedLink === link.id }"
            @click="handleMainPopoverOpen(link)"
            @mouseover="handleMainPopoverHover(link)"
          >
            <div
              :id="link.id"
              class="flex items-center"
            >
              <span
                :id="link.id"
                class="mr-1"
              >
                {{ link.label }}
              </span>
            </div>
            <UIcon
              name="i-heroicons-chevron-down"
              class="mt-0.5 h-3 w-3"
              :class="{ 'rotate-180': openedLink === link.id }"
            />
          </div>
          <NuxtLink
            v-if="link.to"
            :id="link.id"
            :to="link.to"
            class="hover:text-primary flex items-center gap-1 text-sm/6 font-semibold"
          >
            {{ link.label }}
          </NuxtLink>
          <template v-if="link.children">
            <Teleport to="body">
              <MainNavigationPopoverContent
                v-if="link.children && openedLink === link.id"
                :id="link.id"
                class="z-50"
                :items="link.children"
                :title="link.label"
                :type="link.type"
                @click:outside="openedLink = null"
              />
            </Teleport>
          </template>
        </li>
      </ul>
    </template>

    <template #right>
      <ClientOnly>
        <ColorModeButton />
        <ThemeButton />
        <template #fallback>
          <div class="h-8 w-4"></div>
        </template>
      </ClientOnly>
      <UPopover class="flex">
        <ClientOnly>
          <UButton
            color="gray"
            variant="ghost"
            size="sm"
            :aria-label="t('c.switchLanguage')"
          >
            <UIcon
              class="size-5"
              :name="getLocaleFlag(locale)"
            />
          </UButton>
          <template #fallback>
            <div class="size-5"></div>
          </template>
        </ClientOnly>
        <template #panel>
          <div class="flex flex-col gap-1 rounded-md p-1 shadow-lg">
            <nuxt-link
              v-for="localeItem in availableLocales"
              :key="localeItem.code"
              :to="switchLocalePath(localeItem.code)"
              :aria-label="`Switch to ${localeItem.name}`"
              class="flex cursor-pointer select-none items-center gap-1 rounded-md px-2 py-1.5 pe-7 text-sm text-gray-900 hover:bg-gray-100 dark:text-white hover:dark:bg-gray-800"
              :class="
                locale === localeItem.code ? 'bg-gray-100 dark:bg-gray-900' : ''
              "
              @click="changeLocale(localeItem.code)"
            >
              <UIcon
                class="mb-1 h-6 w-6"
                :name="getLocaleFlag(localeItem.code)"
              />
              <span>{{ localeItem.name }}</span>
            </nuxt-link>
          </div>
        </template>
      </UPopover>
    </template>
  </UHeader>
</template>
<script setup lang="ts">
import { getLocaleFlag } from '~/utils/index'
import MainNavigationPartnersShowcase from '@/components/MainNavigationPartnersShowcase.vue'
import SvgJunoLogo from '@/components/svg/JunoLogo.vue'
import SvgJunoLogoDark from '@/components/svg/JunoLogoDark.vue'

const { locale, locales, t } = useI18n()
const { header } = useAppConfig()
const localePath = useLocalePath()
const switchLocalePath = useSwitchLocalePath()

const headerRef = ref<null | HTMLElement>(null)
const { isHeaderDialogOpen } = useUIState()

const availableLocales = computed(() => {
  // check if locales are of type string
  const localesValue = locales.value as { code: string; name: string }[]

  return localesValue.filter((i) => i.code !== locale.value) as {
    code: string
    name: string
  }[]
})

function changeLocale(newLocale: string) {
  locale.value = newLocale
}

const openedLink = ref<null | string>(null)
function handleMainPopoverOpen(link: keyof typeof links) {
  if (openedLink.value === link.id) {
    openedLink.value = null
    return
  }

  openedLink.value = link.id
}

/* let cleanupFn = () => {} */
let timer: ReturnType<typeof setTimeout>

function handleMainPopoverHover(link: { id: string }) {
  openedLink.value = link.id
}

let onHoverDelayCallback = () => {}
useEventListener(document, 'mousemove', (e) => {
  if (!openedLink.value) return

  const hoveredElement = e.target

  const linkId = openedLink.value

  // @ts-ignore
  const hoveringPopover = hoveredElement.closest(`[data-id="${linkId}"]`)
  // @ts-ignore
  const hoveringLink = hoveredElement.closest(`[data-parent-id="${linkId}"]`)

  if (hoveringPopover || hoveringLink) {
    onHoverDelayCallback = () => {}
    clearTimeout(timer) // Reset the timer since the mouse is still over the element
    return
  }

  onHoverDelayCallback = () => {
    openedLink.value = null
  }

  timer = setTimeout(() => {
    onHoverDelayCallback()
  }, 500)
})

function onMenuButtonClick() {
  isHeaderDialogOpen.value = !isHeaderDialogOpen.value

  nextTick(() => {
    const dialogElement = document.querySelector(
      '#headlessui-portal-root div[data-headlessui-state="open"]'
    )
    if (dialogElement) {
      // add attribute data-lenis-prevent to dialog element
      dialogElement.setAttribute('data-lenis-prevent', 'true')
    }
  })
}

const links = computed(() => [
  {
    label: t('c.product'),
    id: 'product',
    type: 'flat',
    children: [
      {
        label: t('c.issueManagement'),
        description: t('c.issueManagementDesc'),
        to: localePath('/features/issue'),
        icon: 'i-j-issue-type-active',
      },
      {
        label: t('c.roadmapPlanning'),
        description: t('c.roadmapPlanningDesc'),
        to: localePath('/features/roadmap'),
        icon: 'i-j-roadmap-active',
      },
      {
        label: t('c.qualityTesting'),
        description: t('c.qualityTestingDesc'),
        to: localePath('/features/testing'),
        icon: 'i-j-test-modul-active',
      },
      {
        label: t('c.timeManagement'),
        description: t('c.timeManagementDesc'),
        to: localePath('/features/timesheet'),
        icon: 'i-j-timesheet-active',
      },
      {
        label: t('c.projectDocumentation'),
        description: t('c.projectDocumentationDesc'),
        to: localePath('/features/docs'),
        icon: 'i-j-docs-active',
      },
      {
        label: t('c.dashboard'),
        description: t('c.dashboardDesc'),
        to: localePath('/features/dashboard'),
        icon: 'i-j-dashboard-active',
      },
    ],
  },
  {
    label: t('c.pricing'),
    id: 'pricing',
    to: localePath('/pricing'),
  },
  {
    label: t('c.documentation'),
    id: 'documentation',
    to: localePath('/documentation/getting-started'),
  },
  {
    label: t('c.changelog'),
    id: 'changelog',
    to: localePath('/changelog'),
  },
  {
    label: t('c.company'),
    id: 'company',
    type: 'nested',
    children: [
      /*       {
        label: t('c.aboutUs'),
        description: t('c.boostGrowth'),
        to: '/features/feature-1',
        icon: 'i-j-teams-outline',
        childrenTitle: t('c.joinUsToday'),
        childrenDesc: t('c.partnershipDesc'),
        rightComponent: MainNavigationPartnersShowcase,
      }, */
      {
        label: t('c.contacts'),
        description: t('c.getInTouch'), // TODO
        icon: 'i-j-envelope-outline',
        childrenTitle: t('c.wantToTalk'),
        childrenDesc: t('c.needHelp'),
        children: [
          {
            label: t('c.helpCustomerSupport'),
            to: localePath('/help/contacts?form=customer-support'),
            icon: 'i-j-support-outline',
          },
          {
            label: t('c.helpTalkToSales'),
            to: localePath('/help/contacts?form=talk-to-sales'),
            icon: 'i-j-client-outline',
          },
          {
            label: t('c.helpBecomeAPartner'),
            to: localePath('/help/contacts?form=become-a-partner'),
            icon: 'i-j-growth-outline',
          },
          {
            label: t('c.helpReportABug'),
            to: localePath('/help/contacts?form=report-a-bug'),
            icon: 'i-j-bug-outline',
          },

          {
            label: t('c.helpShareAnIdea'),
            to: localePath('/help/contacts?form=share-an-idea'),
            icon: 'i-j-idea-outline',
          },
        ],
      },
      {
        label: t('c.partnershipAndCareer'),
        description: t('c.boostGrowth'),
        icon: 'i-j-growth-outline',
        childrenTitle: t('c.joinUsToday'),
        childrenDesc: t('c.partnershipDesc'),
        childrenSubtitle: t('c.navigation.seeCareer'),
        childrenSubtitleLink: {
          label: t('c.career'),
          to: 'https://denevy.recruitee.com/',
        },
        rightComponent: MainNavigationPartnersShowcase,
        children: [
          {
            label: t('c.ambassador'),
            to: localePath('/partner?type=ambassador'),
          },
          {
            label: t('c.consultant'),
            to: localePath('/partner?type=consultant'),
          },
          {
            label: t('c.reseller'),
            to: localePath('/partner?type=reseller'),
          },
          {
            label: t('c.solutionPartner'),
            to: localePath('/partner?type=solution-partner'),
          },
        ],
      },
      {
        label: t('c.ourPolicies'),
        description: t('c.gdprEtc'),
        to: '/features/feature-3',
        icon: 'i-heroicons-chart-bar',
        childrenTitle: t('c.ourPolicies'),
        childrenClass: 'grid grid-flow-col grid-cols-2 grid-rows-5 gap-1',
        children: [
          {
            label: t('c.privacyPolicy'),
            to: localePath('/documentation/policies/gdpr'),
            icon: 'i-j-user-outline',
          },
          {
            label: t('c.termsOfUse'),
            to: localePath('/documentation/policies/terms-and-conditions'),
            icon: 'i-j-docs-page-outline',
          },
          {
            label: t('c.securityPolicy'),
            to: localePath('/documentation/policies/security-policy'),
            icon: 'i-j-security-outline',
          },
          {
            label: t('c.cloudProtection'),
            to: localePath('/documentation/policies/cloud-protection'),
            icon: 'i-j-cloud-outline',
          },
          {
            label: t('c.licenseTerms'),
            to: localePath('/documentation/policies/license-terms'),
            icon: 'i-j-licence-outline',
          },
          {
            label: t('c.customOnPremise'),
            to: localePath('/documentation/policies/custom-installation'),
            icon: 'i-j-office',
          },
          {
            label: t('c.operationsAndConditions'),
            to: localePath('/documentation/policies/operations-conditions'),
            icon: 'i-j-server-outline',
          },
        ],
      },
    ],
  },
])
</script>
<style lang="scss" scoped>
// fade and slide
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

// fade and slide in / out
.fade-enter-from,
.fade-leave-to {
  opacity: 0.3;
  transform: translateY(-10px);
}
</style>
