<template>
  <div class="flex flex-col items-center justify-center overflow-hidden">
    <Transition
      mode="out-in"
      name="fade"
    >
      <div
        :key="displyedPartner.name"
        class="mt-auto flex flex-col items-center"
      >
        <div class="partner-image relative z-10">
          <NuxtImg
            :src="displyedPartner.image"
            width="140"
            class="relative z-10 mb-6 max-w-40 rounded-3xl"
          />
          <div
            class="absolute min-w-36 rotate-[20] rounded-3xl bg-block-3 shadow-sm backdrop-blur-xl"
          ></div>
        </div>

        <span class="t-medium mb-2 text-center text-text-2">
          {{ displyedPartner.description }}
        </span>
        <span class="t-subtitle mb-3 text-text-1">
          {{ displyedPartner.firstName }}
        </span>
      </div>
    </Transition>
    <div class="mt-auto flex items-center gap-1">
      <div
        v-for="(_partner, index) in partners"
        :key="index"
        class="size-2 cursor-pointer rounded-full"
        :class="index === activeIndex ? 'bg-primary' : 'bg-block-3'"
        @click="activeIndex = index"
      ></div>
    </div>
  </div>
</template>
<script setup lang="ts">
const { t } = useI18n()

const activeIndex = ref(0)
const displyedPartner = computed(() => partners[activeIndex.value])
const partners = [
  {
    image: '/img/eliska_novakova.png',
    name: 'Ing. Eliška Nováková',
    firstName: 'Eliška',
    description: t('c.partnerTestimonial1'),
  },
  {
    image: '/img/petr_sloup.png',
    name: 'Petr Sloup',
    firstName: 'Petr',
    description: t('c.partnerTestimonial4'),
  },

  {
    image: '/img/marketa_mikelova.png',
    name: 'Markéta Mikelová',
    firstName: 'Markéta',
    description: t('c.partnerTestimonial3'),
  },
  {
    image: '/img/nataliy_tahova.png',
    name: 'Nataliya Tahova',
    firstName: 'Nataliya',
    description: t('c.partnerTestimonial2'),
  },
]

// every 10 seconds change the activeIndex
onMounted(() => {
  const interval = setInterval(() => {
    activeIndex.value = (activeIndex.value + 1) % partners.length
  }, 5000)

  onUnmounted(() => {
    clearInterval(interval)
  })
})
</script>
<style lang="scss" scoped>
// fade and slide
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in-out;
}

// fade and slide in / out
.fade-enter-from,
.fade-leave-to {
  opacity: 0.2;
}

.partner-image {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--j-block-3);
    width: 140px;
    height: 140px;
    z-index: 0;
    border-radius: 24px;
    transform: rotate(20deg);
  }
}
</style>
