<template>
  <div
    class="fixed left-0 top-[var(--header-height)] hidden w-full items-center justify-center pt-2 md:flex"
  >
    <div
      ref="container"
      class="container mx-auto grid w-full"
    >
      <div
        v-if="type === 'nested'"
        :data-id="id"
        class="grid w-full grid-cols-3 rounded-2xl border border-block-3 bg-overlay bg-opacity-70 p-4 shadow-md backdrop-blur-lg"
      >
        <!-- Left -->
        <div class="flex flex-col border-r border-block-3 pr-4">
          <span class="t-subtitle p-1 text-text-1">{{ title }}</span>
          <ul class="flex flex-col gap-1">
            <li
              v-for="(item, key) in items"
              :key="key"
              class="flex cursor-pointer items-center gap-4 rounded-lg border p-4 transition-colors duration-200 hover:border-block-3 hover:bg-block-2"
              :class="
                activeItemIndex === key
                  ? 'border-block-3 bg-block-2'
                  : 'border-transparent'
              "
              @click="activeItemIndex = key"
            >
              <div
                class="bg-primary-700/10 flex items-center justify-center rounded-full p-2"
              >
                <UIcon
                  :name="item.icon"
                  class="text-primary size-6"
                />
              </div>

              <div class="flex min-w-0 flex-col">
                <span class="t-button truncate text-text-1">
                  {{ item.label }}
                </span>
                <span class="t-medium truncate text-text-2">
                  {{ item.description }}
                </span>
              </div>
            </li>
          </ul>
        </div>
        <!-- Center -->
        <div
          class="col-span-2 flex flex-col px-4"
          :class="
            displayedItem.rightComponent ? 'xl:col-span-1' : 'xl:col-span-2'
          "
        >
          <span
            v-if="displayedItem.childrenTitle"
            class="t-subtitle mb-3 p-1 text-text-1"
          >
            {{ displayedItem.childrenTitle }}
          </span>
          <p
            v-if="displayedItem.childrenDesc"
            class="t-body mb-3 pl-1 text-text-1"
          >
            {{ displayedItem.childrenDesc }}
          </p>
          <ul
            :class="
              displayedItem.childrenClass
                ? displayedItem.childrenClass
                : 'flex flex-col gap-1'
            "
          >
            <li
              v-for="(childrenItem, childrenKey) in displayedItemChildren"
              :key="childrenKey"
            >
              <ULink
                exact-query
                class="hover:text-primary group flex items-center rounded-lg border border-transparent fill-text-1 px-3 py-2 transition-all duration-200 hover:border-block-3 hover:bg-block-2"
                active-class="bg-block-2 text-primary border border-block-3 fill-primary"
                :to="childrenItem.to"
                @click="emit('click:outside', $event)"
              >
                <UIcon
                  v-if="childrenItem.icon"
                  :name="childrenItem.icon"
                  class="group-hover:fill-primary group-hover:text-primary mr-3 size-5 fill-inherit text-text-1 transition-colors duration-200"
                />
                <span>{{ childrenItem.label }}</span>

                <UIcon
                  name="i-heroicons-arrow-right"
                  class="ml-auto -translate-x-3 text-text-3 opacity-0 transition-all duration-200 group-hover:translate-x-0 group-hover:opacity-100"
                />
              </ULink>
            </li>
          </ul>
          <span
            v-if="displayedItem.childrenSubtitle"
            class="t-subtitle mb-3 mt-4 block p-1 text-text-1"
          >
            {{ displayedItem.childrenSubtitle }}
          </span>
          <ULink
            v-if="displayedItem.childrenSubtitleLink"
            exact-query
            class="hover:text-primary group flex items-center rounded-lg border border-transparent fill-text-1 px-3 py-2 transition-all duration-200 hover:border-block-3 hover:bg-block-2"
            active-class="bg-block-2 text-primary border border-block-3 fill-primary"
            :to="displayedItem.childrenSubtitleLink.to"
          >
            <UIcon
              v-if="displayedItem.childrenSubtitleLink.icon"
              :name="displayedItem.childrenSubtitleLink.icon"
              class="group-hover:fill-primary group-hover:text-primary mr-3 size-5 fill-inherit text-text-1 transition-colors duration-200"
            />
            <span>{{ displayedItem.childrenSubtitleLink.label }}</span>

            <UIcon
              name="i-heroicons-arrow-right"
              class="ml-auto -translate-x-3 text-text-3 opacity-0 transition-all duration-200 group-hover:translate-x-0 group-hover:opacity-100"
            />
          </ULink>
        </div>
        <!-- Right -->
        <div
          v-if="displayedItem && displayedItem.rightComponent"
          class="hidden items-center pl-4 xl:flex"
        >
          <component :is="displayedItem.rightComponent" />
        </div>
      </div>
      <div
        v-else
        :data-id="id"
        class="w-full rounded-2xl border border-block-3 bg-overlay bg-opacity-70 p-4 shadow-md backdrop-blur-lg"
      >
        <!-- <span class="t-subtitle p-1 text-text-1">{{ title }}</span> -->
        <ul class="grid grid-flow-col grid-cols-2 grid-rows-3 gap-1">
          <ULink
            v-for="(item, key) in items"
            :key="key"
            as="li"
            :to="item.to"
            class="flex cursor-pointer items-center gap-4 rounded-lg border p-4 transition-colors duration-200 hover:border-block-3 hover:bg-block-2"
            active-class="border-block-3 bg-block-2"
            inactive-class="border-transparent"
            @click="emit('click:outside', $event)"
          >
            <div
              class="bg-primary-700/10 flex items-center justify-center rounded-full p-2"
            >
              <UIcon
                :name="item.icon"
                class="text-primary size-6"
              />
            </div>

            <div class="flex min-w-0 flex-col">
              <span class="t-button truncate text-text-1">
                {{ item.label }}
              </span>
              <span class="t-medium truncate text-text-2">
                {{ item.description }}
              </span>
            </div>
          </ULink>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const props = defineProps<{
  title: string
  id: string
  type: string
  items: {
    label: string
    description: string
    icon: string
    childrenTitle: string
    childrenDesc: string
    to: string
    rightComponent: Component
    childrenClass?: string
    childrenSubtitle?: string
    childrenSubtitleLink?: {
      label: string
      to: string
    }
    children: {
      label: string
      description: string
      icon: string
      to: string
    }[]
  }[]
}>()

const emit = defineEmits<{ (e: 'click:outside', event: MouseEvent): void }>()

const activeItemIndex = ref(0)

const displayedItem = computed(() => {
  return props.items[activeItemIndex.value]
})
const displayedItemChildren = computed(() => {
  return displayedItem.value.children
})

const container = ref(null)
onClickOutside(container, (event) => {
  console.log('click outside')
  const target = event.target as HTMLElement
  if (target.id === props.id) {
    return
  }
  emit('click:outside', event)
})
</script>
